import React, {ReactNode} from "react"
import {graphql, Link} from "gatsby"
import Plattegrond from "gatsby-theme-nieuwbouw/src/components/media/Plattegrond"
import {Container, Row, Col, Button} from "react-bootstrap"
import Situatie from "../components/Situatie"
import Badges from 'gatsby-theme-nieuwbouw/src/components/nummer/Badges'
import Specificaties from "gatsby-theme-nieuwbouw/src/components/type/Specificaties";
import {Bouwtype} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import Carousel from "gatsby-theme-nieuwbouw/src/components/media/Carousel";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import Prijs from "gatsby-theme-nieuwbouw/src/components/Prijs";

interface Props {
    data: TypeQueryData
    children: ReactNode
}
export default ({ data, children}: Props) => {
    const {bouwtype} = data
    const { plattegronden, hoofdfoto } = bouwtype
    const {childMarkdownRemark} = data.file
    const {frontmatter} = childMarkdownRemark

    return (
        <Page pageTitle={`Type ${bouwtype.algemeen.omschrijving}`} pageDescription={data.file.childMarkdownRemark.frontmatter.description || ""}>
            {data.bouwtype.hoofdfoto &&
            <GatsbyImage
                alt={'hoofdfoto'}
                style={{ maxHeight: 'calc(100vh - 4rem)', minHeight: '800px' }}
                image={data.bouwtype.hoofdfoto.localFile.childImageSharp.gatsbyImageData}
            />
            }
            <Container fluid={'xl'} className={'text-center text-md-left my-5'}>
                <Row>
                    <Col md={6} className="mb-5 mb-md-0">
                        <h1 className={'text-uppercase'}>
                            Type {bouwtype.algemeen.omschrijving}
                        </h1>
                        <div>{frontmatter.subtitle}</div>
                        <span className={'text-primary'}>{frontmatter.soort}</span>
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col md={6} className={'mb-5 mb-md-0'}>
                                <h6 className={"font-weight-bold"}>Bouwnummers:</h6>
                                <Badges bouwnummers={bouwtype.bouwnummers} />
                            </Col>
                            <Col md={6}>
                                <h6 className={"font-weight-bold"}>Specificaties:</h6>
                                <Specificaties bouwtype={bouwtype} />
                                {bouwtype.algemeen.koopaanneemsomVanaf &&
                                <span className={'text-uppercase text-primary'}>v.a. <Prijs prijs={bouwtype.algemeen.koopaanneemsomVanaf} /> v.o.n.</span>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={{offset: 1, span: 6}}>
                        <h6>{frontmatter.tagline}</h6>
                        <p>
                            {frontmatter.description}
                        </p>
                    </Col>
                </Row>
                <Row as={"section"} className={`pb-3 px-3`}>
                    <Col lg={7} className={'p-0'}>
                        {bouwtype.fotos &&
                        <Carousel images={bouwtype.fotos} />
                        }
                    </Col>
                    <Col lg={5} className={'p-0'}>
                        <div className={'p-5'}>
                            <h3>Kenmerken</h3>
                            <div dangerouslySetInnerHTML={{__html: data.file.childMarkdownRemark.html}} />
                        </div>
                    </Col>
                </Row>
                {plattegronden && plattegronden.length > 0 &&
                <section className="my-5">
                    <h2 className={"text-center"}>
                        Indeling <span className={'text-primary'}>type {bouwtype.algemeen.omschrijving}</span>
                    </h2>
                    <Row className={'text-center'}>
                        {plattegronden.map((plattegrond: any) =>
                            <Col key={plattegrond.id} lg={6}>
                                <Plattegrond image={plattegrond} />
                            </Col>
                        )}
                    </Row>
                </section>
                }
                <div className={'py-5 text-center mx-auto'} style={{
                    maxWidth: '768px'
                }}>
                    {frontmatter.extra}
                </div>
                {hoofdfoto &&
                <GatsbyImage
                    alt={"plattegrond"}
                    image={hoofdfoto.localFile.childImageSharp.gatsbyImageData} />
                }
                <section className="my-5">
                    <header>
                        <h3 className={'text-primary'}>Situatie Vaartsche Weelde</h3>
                    </header>
                    {false &&
                    <Situatie bouwnummers={bouwtype.bouwnummers || []}/>
                    }
                    <StaticImage src={'../img/situatie.png'} alt={'situatietekening'} />
                </section>
                <section className={"text-center my-5"}>
                    <h2>Bekijk meer woningen:</h2>
                    {data.other.nodes.map(bouwtype =>
                        <Button variant={"primary"} as={Link} to={`/woningen/type-${bouwtype.fields.slug}`} className={"mx-2 rounded-pill"}>Woningtype {bouwtype.algemeen.omschrijving}</Button>
                    )}
                </section>
            </Container>
        </Page>
    );
}

export interface TypeQueryData {
    file: {
        childMarkdownRemark: {
            id: string
            html: string
            excerpt: string
            frontmatter: {
                title: string
                description: string
                soort: string
                extra: string
                subtitle: string
                tagline: string
            }
        }
    }
    bouwtype: Bouwtype
    other: {
        nodes: Bouwtype[]
    }
}

export const pageQuery = graphql`
    fragment TypePage on MarkdownRemark {
        html
        frontmatter {
            title
            description
            extra
            soort
            subtitle
            tagline
        }
    }
    fragment Bouwtype on Bouwtype {
        ...BouwtypeData
        algemeen {
            omschrijving
            woonhuistype
        }
        fields {
            slug
        }
        hoofdfoto {
            localFile {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
        fotos {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(height: 500, layout: FULL_WIDTH)
                }
            }
        }
        diversen {
            objectcode
        }
        plattegronden {
            ...Plattegrond
        }
        bouwnummers {
            id
            ...BouwnummerArea
        }
    }
`
